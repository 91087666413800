import React, {useState} from 'react';

import {ExpandSVG, CollapseSVG} from './common';

import '../styles/components/common.css';
import '../styles/components/CollapsableSection.css';
import '../styles/components/PolicyInputs.css';

function CollapsableSection(props) {
  const {initialExpand, title, policies, setPolicy} = props;

  const [expand, setExpand] = useState(initialExpand ? initialExpand : false);

  const divs = policies.map((p) => {
    return (
      <div className="program-item" onClick={() => setPolicy(p.id)} key={p.id}>
        {p.info["long_description"].value}
      </div>
    );
  });

  return (
    <div className="policy-info-section-container full">
      <div className="policy-heading" onClick={() => setExpand(!expand)}>
        <div className="collapsable-title">{title}</div>
        {expand ? CollapseSVG : ExpandSVG}
      </div>
      <div className="programs-list">
        {expand ? divs.length > 0 ? divs : <div className="policy-missing-info">No Inputs</div> : null}
      </div>
    </div>
  );
}

export default CollapsableSection;