import React, {useState, useEffect, useRef} from 'react';

import '../styles/components/Dropdown.css';

function Dropdown(props) {
  const {title, list, onSelect, showSearch} = props;
  const [listOpen, setListOpen] = useState(false);
  const [searchText, setSearchText] = useState("");

  const wrapperRef = useRef(null);
  useEffect(() => {
    document.addEventListener("click", handleClickOutside, false);
    return () => { document.removeEventListener("click", handleClickOutside, false)};
  }, []);

  const handleClickOutside = (e) => {
    if (wrapperRef.current && !wrapperRef.current.contains(e.target)) setListOpen(false);
  };

  const itemSelected = (item) => {
    onSelect(item);
    setListOpen(false);
  };

  return (
    <div className="dd-wrapper" ref={wrapperRef}>
      <div className="dd-header" onClick={() => setListOpen(!listOpen)}>
        <div className="dd-header-title">{title === "cbo" ? "Congressional Budget Office" : title}</div>
      </div>
      {listOpen ? (
        <div className="dd-list-container">
          {showSearch ? (
            <div className="dd-searchbar">
              <input className="search-input" placeholder="Search policies" type="text" value={searchText} onChange={(e) => setSearchText(e.target.value)} 
              />
            </div>
          ) : null}
          <div className="dd-list">
            {list.map((item) => {
              let text = item === "cbo" ? "Congressional Budget Office" : item;
              if (searchText && !item.title.toLowerCase().includes(searchText.toLowerCase())) {
                return null;
              }
              return (
                <div className="dd-list-item" key={item.id ? item.id : item} onClick={() => itemSelected(item)}>
                  {item.title ? item.title : text}
                </div>
              );
            })}
          </div>
        </div>
        ) : null
      }
    </div>
  );
}

export default Dropdown;