import React from 'react';
import axios from 'axios';

import {BASE_URL} from '../config';

export const ExpandSVG = (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="black" width="48px" height="48px"><path d="M0 0h24v24H0z" fill="none"/><path d="M16.59 8.59L12 13.17 7.41 8.59 6 10l6 6 6-6z"/></svg>
);

export const CollapseSVG = (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="black" width="48px" height="48px"><path d="M0 0h24v24H0z" fill="none"/><path d="M12 8l-6 6 1.41 1.41L12 10.83l4.59 4.58L18 14z"/></svg>
);

export const RunProgram = (policyID, args, success, failure) => {
  if (!policyID) return;
  axios.post(`${BASE_URL}/run`, {...args, name: policyID})
  .then(res => success(res))
  .catch(e => failure(e));
};

export const TaxAssumptionsOverlap = (a, b) => {
  const toReturn = {
    "discount_rate": {
      title: "Discount Rate",
      type: "percentage",
      default: 3.0,
    },
  };

  const aOptions = a["tax_rate_assumption_options"];
  const bOptions = b["tax_rate_assumption_options"];
  if (!aOptions && !bOptions) {
    return toReturn;
  }

  toReturn["tax_rate_assumption"] = {
    title: "Tax Rate Assumption",
    type: "options",
    default: "",
    options: [],
  };
  toReturn["tax_rate_cont"] = {
    title: "Continuous Tax Rate",
    type: "percentage",
    default: 2.0,
  };

  if (!aOptions) {
    toReturn["tax_rate_assumption"].default = b["tax_rate_assumption"];
    toReturn["tax_rate_assumption"].options = bOptions;
    return toReturn;
  }
  if (!bOptions) {
    toReturn["tax_rate_assumption"].default = a["tax_rate_assumption"];
    toReturn["tax_rate_assumption"].options = aOptions;
    return toReturn;
  }

  const intersect = aOptions.filter(v => bOptions.includes(v));
  if (intersect.length === 0) {
    delete toReturn["tax_rate_cont"];
    delete toReturn["tax_rate_assumption"];
    return toReturn;
  }
  toReturn["tax_rate_assumption"].default = intersect[0];
  toReturn["tax_rate_assumption"].options = intersect;
  return toReturn;
};