import React, {useState} from 'react';

import {ExpandSVG, CollapseSVG} from './common';
import Input from './Input';

import '../styles/components/common.css';
import '../styles/components/PolicyInputs.css';

function PolicyInputs(props) {
  const {argInfo, args, setArgs, initialExpand, expandOrFund} = props;

  const [expand, setExpand] = useState(initialExpand ? initialExpand : false);

  const handleChange = (key, val) => {
    if (key !== null) {
      const updatedArgs = {...args};
      updatedArgs[key] = val;
      setArgs(updatedArgs, expandOrFund);
    }
  };

  const argDivs = Object.keys(args).map((a) => {
    return <Input argData={argInfo[a]} value={args[a]} arg={a} handleChange={handleChange} key={a} />;
  });

  return (
    <div className="policy-info-section-container">
      <div className="policy-heading" onClick={() => setExpand(!expand)}>
        <div className="policy-heading-title">Adjustable Inputs</div>
        {expand ? CollapseSVG : ExpandSVG}
      </div>
      {expand ? argDivs.length > 0 ? argDivs : <div className="policy-missing-info">No Inputs</div> : null}
    </div>
  );
}

export default PolicyInputs;