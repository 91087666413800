import React, {useState, useEffect} from 'react';

import {RunProgram} from './common';
import PolicyInfo from './PolicyInfo';
import PolicyInputs from './PolicyInputs';
import PolicyOutputs from './PolicyOutputs';

import '../styles/components/PolicyView.css';

function PolicyView(props) {
  const {policyData} = props;
  const [args, setArgs] = useState({});
  const [argInfo, setArgInfo] = useState({});
  const [results, setResults] = useState({});

  const runProgram = () => {
    if (!policyData.id) return;
    RunProgram(policyData.id, args,
      (res) => setResults(res.data),
      (e) => alert(`Error calculating MVPF for ${policyData.id}: ${e}`)
    );
  };

  const updateArgs = (updated) => {
    const info = {};
    const updateInfo = (paper) => {
      const initArgs = [];
      const paperInputs = policyData.arguments["paper-options"][paper];
      if (paperInputs && paperInputs.inputs && Object.keys(paperInputs.inputs).length > 0) {
        for (let a of Object.keys(paperInputs.inputs)) {
          initArgs[a] = paperInputs.inputs[a].default;
          info[a] = paperInputs.inputs[a];
        }
      }
      return initArgs
    };

    if (Object.keys(args).length === 0) {
      if (policyData.arguments) {
        let initialArgs = {};
        for (let a of Object.keys(policyData.arguments)) {
          if (a !== "paper-options") {
            initialArgs[a] = policyData.arguments[a].default;
            info[a] = policyData.arguments[a];
          }
        }
        if (policyData.arguments["paper-options"]) {
          const toAdd = updateInfo(initialArgs["paper"]);
          initialArgs = {...initialArgs, ...toAdd};
        }
        setArgInfo(info);
        setArgs(initialArgs);
      }
    }
    else if (updated.paper && args.paper && updated.paper !== args.paper) {
      let updatedArgs = {paper: updated.paper};
      info.paper = policyData.arguments.paper;
      if (policyData.arguments["paper-options"]) {
        const toAdd = updateInfo(updated.paper);
        updatedArgs = {...updatedArgs, ...toAdd};
      }
      setArgInfo(info);
      setArgs(updatedArgs);
    }
    else {
      setArgs(updated);
    }
  };

  useEffect(() => {
    window.scrollTo(0, 0);
    updateArgs();
    runProgram();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="policy-container">
      <div className="policy-title">{policyData.info["long_description"].value}</div>
      <PolicyInputs argInfo={argInfo} args={args} setArgs={updateArgs} initialExpand={true} />
      <div className="policy-button-container">
        <button className="policy-recalculate-button" onClick={runProgram}> RECALCULATE </button>
      </div>
      <PolicyOutputs outputs={results} />
      <PolicyInfo info={policyData.info} initialExpand={true} />
    </div>
  );
}

export default PolicyView;