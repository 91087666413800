import React from 'react';

import Dropdown from './Dropdown';

import '../styles/components/Input.css';

function Input(props) {
  const {argData, value, arg, handleChange} = props;
  
  const testNumericalInput = (e) => {
    const re = /^-?[0-9\b]+(\.?[0-9\b]+)?$/;
    if (e.target.value === "") {
      handleChange(arg, "");
    } else if (re.test(e.target.value)) {
      handleChange(arg, parseFloat(e.target.value));
    }
  };

  let input = null;
  if (argData.type === "dollar" || argData.type === "percentage") {
    input = (
      <>
        {argData.type === "dollar" ? <div className="input-qualifier">$</div> : null}
        <input 
          className="input"
          type="number"
          min={argData.min ? argData.min : -99999999}
          max={argData.max ? argData.max : 99999999}
          step={`${argData["round_to"] ? Math.pow(10, -1*argData["round_to"]) : 0.1}`}
          value={value}
          onChange={(e) => testNumericalInput(e)} 
        />
        {argData.type === "percentage" ? <div className="input-qualifier">%</div> : null}
      </>
    );
  }
  if (argData.type === "options") {
    input = (
      <Dropdown 
        title={value ? value : "Select Assumption"}
        list={argData.options}
        onSelect={(v) => handleChange(arg, v)}
      />
    );
  }

  return (
    <div className="input-div">
      <div className="input-info">
        <div className="input-title">{argData.title}</div>
        <div className="input-description">{argData.description}</div>
      </div>
      <div className="input-container">
        {input}
      </div>
    </div>
  );
}

export default Input;