import React, {useState, useEffect} from 'react';
import axios from 'axios';

import {BASE_URL} from './config';
import CollapsableSection from './components/CollapsableSection';
import ComparisonView from './components/ComparisonView';
import Dropdown from './components/Dropdown';
import PolicyView from './components/PolicyView';

import mvpf from './images/mvpf.png';

import './styles/App.css';

function App() {
  const [policys, setPolicys] = useState({});
  const [policy, setPolicy] = useState(null);
  const [showComparison, setShowComparison] = useState(false);

  const [expandPolicy, setExpandPolicy] = useState(null);
  const [fundPolicy, setFundPolicy] = useState(null);

  const [authenticated, setAuthenticated] = useState(false);

  useEffect(() => {
    axios.get(`${BASE_URL}/program`)
      .then(res => setPolicys(res.data));
  }, []);

  let content = null;
  if (showComparison) {
    content = (
      <ComparisonView expandPolicy={expandPolicy} fundPolicy={fundPolicy} />
    );
  } else if (policy) {
    content = (
      <PolicyView policyData={policys[policy]} />
    );
  } else {
    const policyArray = Object.keys(policys).map((key) => {
      return {...policys[key], title: policys[key].info["long_description"].value};
    });
    const expandOptions = policyArray.filter((v) => {
      if (fundPolicy && v.id === fundPolicy.id) return false;
      return v.id !== "tax_raise"; 
    });
    const fundOptions = policyArray.filter((v) => {
      if (expandPolicy && v.id === expandPolicy.id) return false;
      return v.id !== "tax_cut"; 
    });

    const policySections = {};
    policyArray.forEach((v) => {
      let section = "Uncategorized";
      if (v.info["prog_type"] && v.info["prog_type"].value) {
        section = v.info["prog_type"].value;
      }
      if (policySections[section]) {
        policySections[section].push(v);
      } else {
        policySections[section] = [v];
      }
    });

    const tryToCompare = () => {
      if (fundPolicy && expandPolicy) {
        setShowComparison(true);
      } else {
        alert("Please select a policy to expand and choose a policy to fund the expansion.");
      }
    };

    content = (
      <>
        <div className="comparison-section-container">
          <div className="comparison-section-title">Choose a Policy to Expand and a Policy to Fund the Expansion</div>
          <div className="comparison-section-policy-option">
            <div className="comparison-section-policy-header">Expand a Policy:</div>
            <Dropdown 
              title={expandPolicy ? expandPolicy.title : "Select Policy"}
              list={expandOptions}
              showSearch={true}
              onSelect={(v) => setExpandPolicy(v)}
            />
          </div>
          <div className="comparison-section-policy-option">
          <div className="comparison-section-policy-header">Fund the Expansion:</div>
            <Dropdown 
              title={fundPolicy ? fundPolicy.title : "Select Policy"}
              list={fundOptions}
              showSearch={true}
              onSelect={(v) => setFundPolicy(v)}
            />
          </div>
          <div className="comparison-section-button-container">
            <button className="comparison-section-button" onClick={() => tryToCompare()}> COMPARE </button>
          </div>
        </div>

        <div className="section-container">
          <div className="section-description">
            The user chooses a policy to expand (“expanded policy”) and specified how they would like to fund the expansion (“funding policy”). The expanded policy is paid for via reduced spending on the funding policy. Using evidence from peer reviewed publications detailed in 
            <a className="link-text" href="https://opportunityinsights.org/wp-content/uploads/2019/07/Welfare_paper.pdf" target="_blank" rel="noopener noreferrer">Hendren and Sprung-Keyser (2020)</a>
            , the tool computes the welfare implications of this policy experiment. Specifically, the tool uses estimates of the Marginal Value of Public Funds (MVPF) for each policy to generate a prediction for the cost that would be imposed on the recipients of the funding policy in order to generate $1 of benefits for the recipients of the expanded policy. The tool compares this cost to the cost of delivering $1 of benefits to the recipients of the expand policy via a tax cut that is funded by a tax raise on the recipients of the funding policy. The lower the cost of generating $1 of benefits the more “efficient” the policy experiment. 
          </div>
        </div>

        <div className="section-container">
          <div className="section-title">Currently Supported Policies</div>
          <div className="programs-list">
            {Object.keys(policySections).map((s) => (
              <CollapsableSection title={s} policies={policySections[s]} setPolicy={setPolicy} key={s} />
            ))}
          </div>
        </div>
      </>
    );
  }

  const headerClicked = () => {
    setPolicy(null);
    setShowComparison(false);
  }

  const [passwordVal, setPasswordVal] = useState("");
  const password = "MVPFPASSWORD2020!";
  const tryAuth = () => {
    if (passwordVal === password) setAuthenticated(true);
    else alert("INCORRECT PASSWORD!");
  };

  if (!authenticated) {
    content = (
      <div className="password-container">
        <div className="enter-password-prompt">Please Enter the Password To Continue</div>
        <input 
            className="password-input" 
            type="text"
            value={passwordVal} 
            onChange={(e) => setPasswordVal(e.target.value)} 
          />
        <div className="enter-password-button-container">
          <button className="enter-password-button" onClick={tryAuth}> AUTHENTICATE </button>
        </div>
      </div>
    );
  }

  return (
    <div className="app">
      <div className="header">
        <img className="logo" src={mvpf} alt="MVPF" onClick={() => headerClicked()}></img>
      </div>
      {content}
    </div>
  );
}

export default App;
