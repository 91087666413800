import React, {useState} from 'react';

import {ExpandSVG, CollapseSVG} from './common';

import '../styles/components/common.css';
import '../styles/components/PolicyOutputs.css';

function PolicyOutputs(props) {
  const {outputs, exclude} = props;

  const [expand, setExpand] = useState(true);

  const outputDivs = Object.keys(outputs).map((a) => {
    if (exclude && exclude[a]) return null;
  
    let value = outputs[a].value.toFixed(2);
    if (a === "mvpf") value = outputs[a].value.toFixed(2);
    else value = `$${value}`;
    
    const showInf = a === "mvpf" && value < 0 && outputs["total_cost"].value < 0 && outputs["wtp"].value > 0;

    return (
      <div className="policy-output-div" key={a}>
        <div className={"policy-output-title" + (a === "mvpf" ? " mvpf-title" : "")}>
          {outputs[a].title}
        </div>
        :
        <div className={"policy-output-value" + (a === "mvpf" ? " mvpf-value" : "")}>
          {showInf ? <span className="infinity">∞</span> : value}
        </div>
      </div>
    );
  });

  return (
    <div className="policy-info-section-container">
      <div className="policy-heading" onClick={() => setExpand(!expand)}>
        <div className="policy-heading-title">Policy Outputs</div>
        {expand ? CollapseSVG : ExpandSVG}
      </div>
      {expand ? outputDivs : null}
    </div>
  );
}

export default PolicyOutputs;