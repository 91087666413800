import React, {useState} from 'react';

import {ExpandSVG, CollapseSVG} from './common';

import '../styles/components/common.css';
import '../styles/components/PolicyInfo.css';

function PolicyInfo(props) {
  const {info, initialExpand} = props;

  const [expand, setExpand] = useState(initialExpand ? initialExpand : false);

  const infoDivs = Object.keys(info).map((a) => {
    if (!info[a].title) return null;

    let value = info[a].value;
    if (typeof value === "number") {
      value = `${info[a].value.toFixed(0)}`;
      if (info[a].type === "dollar") value = `$${info[a].value.toFixed(2)}`;
    }
    else if (info[a].type && info[a].type === "list") {
      value = info[a].value.map((v) => {
        if (v.includes("](")) {
          const text = v.substring(1, v.indexOf("]("));
          const src = v.substring(v.indexOf("](")+2, v.length-1);
          return (
            <div key={v}>
              <a className="link-text" href={src} target="_blank" rel="noopener noreferrer">
                {text}
              </a>
            </div>
          );
        }
        return <div key={v}>{v}</div>
      });
    }
    if (info[a].options) {
      value = info[a].options[value];
    }
    
    return (
      <div className="policy-info-div" key={a}>
        <div className="policy-info-title">{info[a].title}:</div>
        <div className="policy-info-value">{value}</div>
      </div>
    );
  });

  return (
    <div className="policy-info-section-container">
      <div className="policy-heading" onClick={() => setExpand(!expand)}>
        <div className="policy-heading-title">Policy Information</div>
        {expand ? CollapseSVG : ExpandSVG}
      </div>
      {expand ? infoDivs : null}
    </div>
  );
}

export default PolicyInfo;