import React, {useState, useEffect} from 'react';

import {RunProgram, TaxAssumptionsOverlap} from './common';
import PolicyInfo from './PolicyInfo';
import PolicyInputs from './PolicyInputs';
import PolicyOutputs from './PolicyOutputs';

import '../styles/components/ComparisonView.css';
import Input from './Input';

function ComparisonView(props) {
  const {expandPolicy, fundPolicy} = props;

  const [expandResults, setExpandResults] = useState({});
  const [expandArgs, setExpandArgs] = useState({});
  const [expandArgInfo, setExpandArgInfo] = useState({});

  const [fundResults, setFundResults] = useState({});
  const [fundArgs, setFundArgs] = useState({});
  const [fundArgInfo, setFundArgInfo] = useState({});

  const [showModal, setShowModal] = useState(false);

  const taxAssumptions = TaxAssumptionsOverlap(expandPolicy["tax_information"], fundPolicy["tax_information"]);

  const runProgram = (policyID, args, setResults) => {
    if (!policyID) return;
    RunProgram(policyID, args,
      (res) => setResults(res.data),
      (e) => alert(`Error calculating MVPF for ${policyID}: ${e}`)
    );
  };

  const updateArgs = (updated, fundOrExpand) => {
    let policyData = {};
    let args = {};
    let setArgInfo = null;
    let setArgs = null;

    if (fundOrExpand === "expand") {
      policyData = expandPolicy;
      setArgInfo = setExpandArgInfo;
      setArgs = setExpandArgs;
      args = expandArgs;
    }
    if (fundOrExpand === "fund") {
      policyData = fundPolicy;
      setArgInfo = setFundArgInfo;
      setArgs = setFundArgs;
      args = fundArgs;
    }

    const info = {};
    const updateInfo = (paper) => {
      const initArgs = [];
      const paperInputs = policyData.arguments["paper-options"][paper];
      if (paperInputs && paperInputs.inputs && Object.keys(paperInputs.inputs).length > 0) {
        for (let a of Object.keys(paperInputs.inputs)) {
          initArgs[a] = paperInputs.inputs[a].default;
          info[a] = paperInputs.inputs[a];
        }
      }
      return initArgs
    };

    if (Object.keys(args).length === 0) {
      if (policyData.arguments) {
        let initialArgs = {};
        for (let a of Object.keys(policyData.arguments)) {
          if (a !== "paper-options") {
            initialArgs[a] = policyData.arguments[a].default;
            info[a] = policyData.arguments[a];
          }
        }
        if (policyData.arguments["paper-options"]) {
          const toAdd = updateInfo(initialArgs["paper"]);
          initialArgs = {...initialArgs, ...toAdd};
        }
        setArgInfo(info);
        setArgs(initialArgs);
        return initialArgs;
      }
    }
    else if (updated.paper && args.paper && updated.paper !== args.paper) {
      let updatedArgs = {paper: updated.paper};
      info.paper = policyData.arguments.paper;
      if (policyData.arguments["paper-options"]) {
        const toAdd = updateInfo(updated.paper);
        updatedArgs = {...updatedArgs, ...toAdd};
      }
      setArgInfo(info);
      setArgs(updatedArgs);
      return updatedArgs;
    }
    else {
      setArgs(updated);
    }
  };

  useEffect(() => {
    window.scrollTo(0, 0);
    
    // let initialArgs = {};
    // for (let a of Object.keys(expandPolicy.arguments)) {
    //   initialArgs[a] = expandPolicy.arguments[a].default;
    // }
    // setExpandArgs(initialArgs);
    let initialArgs = updateArgs({}, "expand");
    runProgram(expandPolicy.id, initialArgs, setExpandResults);

    // initialArgs = {};
    // for (let a of Object.keys(fundPolicy.arguments)) {
    //   initialArgs[a] = fundPolicy.arguments[a].default;
    // }
    // setFundArgs(initialArgs);
    initialArgs = updateArgs({}, "fund");
    runProgram(fundPolicy.id, initialArgs, setFundResults);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const recalculate = (e) => {
    e.preventDefault();
    runProgram(expandPolicy.id, expandArgs, setExpandResults);
    runProgram(fundPolicy.id, fundArgs, setFundResults);
  };

  let mvpfRatioDiv = null;
  let mvpfRatio = null;
  if (expandResults.mvpf && fundResults.mvpf) {
    mvpfRatio = fundResults.mvpf.value / expandResults.mvpf.value;
    let text = "";
    if (isNaN(mvpfRatio)) {
      mvpfRatio = null;
      text = "Error Calculating Resource Transfer Rate";
    } else if (expandResults.mvpf.value < 0 && expandResults["total_cost"].value < 0 && expandResults.wtp.value > 0) {
      mvpfRatio = "N/A";
      text = "The expanded policy pays for itself in the long-run.";
    } else if (expandResults.mvpf.value < 0 && expandResults["total_cost"].value > 0 && expandResults.wtp.value < 0) {
      mvpfRatio = "N/A";
      text = "The expanded policy costs the government money and recipients have a negative willingness to pay.";
    } else if (fundResults.mvpf.value < 0 && fundResults["total_cost"].value < 0 && fundResults.wtp.value > 0) {
      mvpfRatio = "N/A";
      text = "This expansion can be funded by expanding, rather than reducing, the funding policy since this policy has an infinite MVPF and therefore increases government revenue.";
    } else {
      mvpfRatio = mvpfRatio.toFixed(2);
      text = <>This policy delivers <span className="ratio green">$1</span> of benefits to the recipients of the expanded policy at a cost of <span className="ratio red">${mvpfRatio}</span> to the recipients of the funding policy.</>;
    }
    mvpfRatioDiv = <div className="comparison-view-transfer-description">{text}</div>;
  }

  let taxRatioDiv = null;
  let taxRatio = null;
  if (fundPolicy.data["tax_benef_mvpf"] && expandPolicy.data["tax_benef_mvpf"]) {
    taxRatio = fundPolicy.data["tax_benef_mvpf"] / expandPolicy.data["tax_benef_mvpf"];
  } 
  else if (fundPolicy.data["tax_benef_mvpf"] && expandPolicy.id === "tax_cut" && expandResults.mvpf) {
    taxRatio = fundPolicy.data["tax_benef_mvpf"] / expandResults.mvpf.value;
  }
  else if (expandPolicy.data["tax_benef_mvpf"] && fundPolicy.id === "tax_raise" && fundResults.mvpf) {
    taxRatio = fundResults.mvpf.value / expandPolicy.data["tax_benef_mvpf"];
  }
  else if (expandPolicy.id === "tax_cut" && expandResults.mvpf && fundPolicy.id === "tax_raise" && fundResults.mvpf) {
    taxRatio = fundResults.mvpf.value / expandResults.mvpf.value;
  }

  if (taxRatio) {
    let text = "";
    if (isNaN(taxRatio)) {
      taxRatio = null;
      text = "Error Calculating Tax Code Resource Transfer Rate";
    }  else {
      taxRatio = taxRatio.toFixed(2);
      text = <>By changing the tax code we could deliver <span className="ratio green">$1</span> of benefits to individuals with incomes similar to those who receive the expanded policy at a cost of <span className="ratio red">${taxRatio}</span> to those with incomes similar to those who receive the funding policy.</>;
    }
    taxRatioDiv = <div className="comparison-view-transfer-description">{text}</div>;
  }

  let taxQualifier = null;
  let taxQualifierDiv = null;
  if (mvpfRatio && taxRatio) {
    if (mvpfRatio !== "N/A") {
      taxQualifier = "just as efficient as"
      if (mvpfRatio < taxRatio) taxQualifier = "more efficient than";
      else if (mvpfRatio > taxRatio) taxQualifier = "less efficient than";

      taxQualifierDiv = <>The policy expansion is {taxQualifier} transferring resources through the tax code.</>;
    } else {
      if (expandResults.mvpf.value < 0) {
        if (expandResults.wtp.value > 0) {
          taxQualifier = "more efficient than";
          taxQualifierDiv = <>The policy expansion is {taxQualifier} transferring resources through the tax code because the expanded policy pays for itself in the long-run.</>;
        } else {
          taxQualifier = "less efficient than";
          taxQualifierDiv = <>The policy expansion is {taxQualifier} transferring resources through the tax code because the government has to pay for the policy.</>;
        }
      } else {
        taxQualifier = "more efficient than";
        taxQualifierDiv = <>The policy expansion is {taxQualifier} transferring resources through the tax code because the funding policy raises government revenue and provides benefits to the recipients.</>;
      }
    }
  }

  const handleAssumptionsChange = (key, val) => {
    if (val !== null) {
      let updatedArgs = {...expandArgs};
      updatedArgs[key] = val;
      setExpandArgs(updatedArgs);

      updatedArgs = {...fundArgs};
      updatedArgs[key] = val;
      setFundArgs(updatedArgs);
    }
  };

  let modal = null;
  if (showModal) {
    modal = (
      <div className="modal-container">
        <div className="modal">
          <div className="comparison-view-summary-title">Set Assumptions For Both Policies</div>
          <div className="assumptions-container">
            <div className="assumptions-section">
              <div className="assumptions-title">Tax Assumptions</div>
              {
                Object.keys(taxAssumptions).map((v) => {
                  if (v === "tax_rate_cont") {
                    if (fundArgs["tax_rate_assumption"] !== undefined && fundArgs["tax_rate_assumption"] !== "continuous") {
                      return null;
                    }
                    if (fundArgs["tax_rate_assumption"] === undefined && taxAssumptions[v].default !== "continuous") {
                      return null;
                    }
                  }
                  return (
                    <Input 
                      argData={taxAssumptions[v]}
                      value={fundArgs[v] ? fundArgs[v] : taxAssumptions[v].default}
                      arg={v}
                      handleChange={handleAssumptionsChange}
                      key={v} 
                    />
                  )
                })
              }
            </div>
          </div>
          <button className="policy-recalculate-button" onClick={() => setShowModal(!showModal)}>Done</button>
        </div>
      </div>
    );
  }

  return (
    <>
      {modal}
      <div className="comparison-view-recalculate-button-container">
        <button className="policy-recalculate-button" onClick={() => setShowModal(!showModal)}> Set Assumptions </button>
        <button className="policy-recalculate-button" onClick={(e) => recalculate(e)}> RECALCULATE </button>
      </div>
      <div className="comparison-view-container">
        <div className="comparison-view-summary-container">
          <div className="comparison-view-summary-title-container">
            <div className="comparison-view-summary-title-section">
              <div className="comparison-view-summary-title">Expand a Policy</div>
              <div className="comparison-view-summary-subtitle">{expandPolicy.info["long_description"].value}</div>
            </div>
            <div className="comparison-view-summary-title-section">
              <div className="comparison-view-summary-title">Fund the Expansion</div>
              <div className="comparison-view-summary-subtitle">{fundPolicy.info["long_description"].value}</div>
            </div>
          </div>
          <div className="comparison-view-transfers-container">
            <div className="comparison-view-transfer-container">
              <div className="comparison-view-transfer-title">
                Resource Transfer Rate: {mvpfRatio !== null ? <span className="">{mvpfRatio}</span> : null}
              </div>
              {mvpfRatioDiv}
            </div>
            <div className="comparison-view-transfer-container">
              <div className="comparison-view-transfer-title">
                Tax Code Resource Transfer Rate: {taxRatio !== null ? <span className="">{taxRatio}</span> : null}
              </div>
              {taxRatioDiv}
            </div>
            {taxQualifierDiv ? (
              <div className={"comparison-view-summary" + (taxQualifier === "more efficient than" ? " green" : taxQualifier === "less efficient than" ? " red" : "")}>
                {taxQualifierDiv}
              </div>
            ) : null}
          </div>
        </div>
        <div className="comparison-view-policies-container">
          <div className="comparison-view-policy-container">
            <PolicyOutputs outputs={expandResults} exclude={{program_cost: true}} />
            <PolicyInputs argInfo={expandArgInfo} args={expandArgs} setArgs={updateArgs} expandOrFund={"expand"} />
            <PolicyInfo info={expandPolicy.info} />
          </div>
          <div className="comparison-view-policy-container">
            <PolicyOutputs outputs={fundResults} exclude={{program_cost: true}} />
            <PolicyInputs argInfo={fundArgInfo} args={fundArgs} setArgs={updateArgs} expandOrFund={"fund"} />
            <PolicyInfo info={fundPolicy.info} />
          </div>
        </div>
      </div>
    </>
  );
}

export default ComparisonView;